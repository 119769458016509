<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card class="flex-center">
          <transition name="router-anim" :enter-active-class="`animated fadeInUp`" mode="out-in" :leave-active-class="`animated fadeOut`">
            <router-view></router-view>
          </transition>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  mounted () {
    core.index()
  }
}
</script>
<style lang="scss" scoped>

.flex-center{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}
.card{
  background: transparent !important;
  min-height: 80vh;
}
</style>
